import React, { createContext, useState } from "react";
import { createBrowserRouter, createHashRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import PrivateRoutes from "./components/auth/PrivateRoutes";
import Cart from "./pages/Cart";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home3";
import Landing from "./pages/Landing";
import Login from "./pages/Login";
import ProductDetails from "./pages/product-details/ProductDetails";
import UserProfile from "./pages/UserProfile";
import CoreWrapper from "./layout/CoreWrapper";
import ProductTable from "./components/table/ProductTable";
import UploadedFiles from "./pages/UploadedFiles";
import UploadFileButtonForm from "./components/forms/UploadFileButtonForm";
import CoreLayout from "./layout/CoreLayout";

import CytoscapeWithMantine from "./components/CytoscapeWithMantine";
import CytoscapeWithMantine2 from "./components/CytoscapeWithMantine2";
import CytoscapeWithMantine3 from "./components/CytoscapeWithMantine3";
import CytoscapeWithMantine8 from "./components/CytoscapeWithMantine8";
import CytoscapeWithMantine13 from "./components/CytoscapeWithMantine13";
import { NodeSingular } from "cytoscape";
import { NodeProps } from "./types/interfaces";
import MermaidDiagram from "./components/diagram/MermaidDiagram";


import { AppProvider } from "./provider/AppProvider";
import GraphPage from "./pages/GraphPage";
import MermaidPage from "./pages/MermaidPage";
import DraggableModal from "./components/DraggableModal1";
import DraggableComponent from "./components/DraggableComponent";
import HelloWorldModal from "./components/HelloWorldModal";
import MyComponent from "./components/DraggableModal1";
import DraggableModal2 from "./components/DraggableModal2";
import DraggableModal3 from "./components/DraggableModal3";
import DraggableModal4 from "./components/DraggableModal4";
import DataExplorerPage from "./components/DataExplorerPage";
import TableauPrepPage from "./pages/TableauPrepPage";

// Hacking up for AppShell

// Removed the queryClient, and it's export from ./util/http,
// and instantiating it here. I will then use useQueryClient() to
// get the current instance.
// https://stackoverflow.com/questions/71540973/why-use-usequeryclient-from-react-query-library

// This is NOT the way that the Udemy instructor did it in
// ~/workspace/react/udemy-course/new-24-tanstack-query.

// ADT: converting this to use createBrowserRouter instead of BrowserRouter,
// per: https://reactrouter.com/en/main/upgrading/v6-data#differences
// Also see the Udemy example, in my workspace/react/udemy-course directory
// new-24-tanstack-query/src/App.jsx, and
// https://github.com/remix-run/react-router/issues/10637
// https://stackoverflow.com/questions/75785717/i-am-using-createbrowserrouter-what-is-the-proper-way-to-have-header-and-footer
// since this file is a bastardization of all of them

// Create a client. This is a singleton. Access it elsewhere with
// const queryClient = useQueryClient();
const queryClient = new QueryClient();

export const NodeContext = createContext<NodeProps | undefined>(undefined);
// 3️⃣ Router singleton created
  const router = createHashRouter([
  {

    element: <CoreWrapper />,
    children: [
      { path: "/", element: <Landing /> },
      { path: "/login", element: <Login /> },
      {
        element: <PrivateRoutes />,
        children: [
          {
            path: "/app", 
            // Wrap CoreLayout with AppProvider, which I'm using for my
            // cytoscape reference. It can also be used for anything else that
            // needs access to references for the header and child pages.
            element: (
              <AppProvider>
                <CoreLayout />
              </AppProvider>),
            children: [
              { index: true, element: <Home /> },
              { path: "product/:productId", element: <ProductDetails /> },
              { path: "products", element: <ProductTable/>},  
              { path: "upload", element: <UploadFileButtonForm/>},
              { path: "uploaded", element: <UploadedFiles/>},
              { path: "cart", element: <Cart /> },
              { path: "my profile", element: <UserProfile /> },
              { path: "dashboard", element: <Dashboard /> },
              { path: "mgraph2", element: <CytoscapeWithMantine/>},
              { path: "mgraph3", element: <CytoscapeWithMantine2/>},
              { path: "mgraph3", element: <CytoscapeWithMantine3/>},
              { path: "mgraph8", element: <CytoscapeWithMantine8/>},              	                                                
              { path: "mgraph13", element: <CytoscapeWithMantine13/>} ,
              {/* path: "file/:filename", element: <FileDetails />*/},
//              { path: "file/:filename", element: <CytoscapeGraph />},   
              { path: "file/:filename", element: <GraphPage />},  
              { path: "tableau-prep/:filename", element: <GraphPage />},
              { path: "tableau-prep/diagram/:filename", element: <MermaidPage />},	      
              { path: "mermaid", element: <MermaidDiagram/>},
              { path: "draggable-modal1", element: <MyComponent/>},
              { path: "draggable-modal2", element: <DraggableModal2/>},
              { path: "draggable-modal3", element: <DraggableModal3/>},              
              { path: "draggable-modal4", element: <DraggableModal4/>},                            
              { path: "draggable", element: <DraggableComponent/>},
              { path: "modal", element: <HelloWorldModal/>},   
              { path: "explorer", element: <DataExplorerPage/>}           
            ],
          },
        ],
      },
    ],
  },
]);


// 4️⃣ RouterProvider added
export default function App() {
  const [node, setNode] = useState<NodeSingular | null>(null);  


  return (
    <QueryClientProvider client={queryClient}>
      <NodeContext.Provider value={{
        node: node, 
        setNode: setNode,
      }}
      >
      <RouterProvider router={router}/>
      </NodeContext.Provider>            
    </QueryClientProvider>
  
  );
}

